import React, { useCallback } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { PageStatus, TemporaryPageStatus, EventType } from '@repo/constants';
import { toast } from 'react-hot-toast';
import { Helmet } from 'react-helmet';
import { getUA } from 'react-device-detect';
import { configurePage } from '@repo/page-configurator';

import NoteClaimedState from '../components/NoteStates/Claimed';
import NoteCreateState from '../components/NoteStates/Create';
import NoteDeletedState from '../components/NoteStates/Deleted';
import NoteErrorState from '../components/NoteStates/Error';
import NoteViewState from '../components/NoteStates/View';
import NoteProcessingState from '../components/NoteStates/Processing';
import NoteStateSkeleton from '../components/NoteStates/Skeleton';
import NoteUnderReviewState from '../components/NoteStates/UnderReview';
import NoteErrorOutage from '../components/NoteStates/ErrorOutage';

import Footer from '../components/Footer';
import { createEvent, getPage, updatePage } from '../libs/api';
import useLocalStorage from '../hooks/useLocalStorage';
import { useAuth } from '../hooks/useAuth';
import { isPhotoUpload } from '../libs/config';
import NoteErrorNotFoundState from '../components/NoteStates/ErrorNotFound';

import useMetaPixel from '../hooks/useMeta';
import useGTM from '../hooks/useGTM';

function AccessContent() {
  const { pageId } = useParams();
  const [searchParams] = useSearchParams();
  const [localPageOwner, setLocalPageOwner] = useLocalStorage(`PAGE-${pageId}`);
  const [accessUrls, setAccessUrls] = useLocalStorage('accessUrls');
  const { currentUser } = useAuth();
  const [isLoading, setIsLoading] = React.useState(true);
  const [page, setPage] = React.useState(null);
  const [pageStatus, setPageStatus] = React.useState(null);

  const config = React.useMemo(() => configurePage(page), [page]);

  React.useEffect(() => {
    if (config?.userAnalytics) {
      if (config?.userAnalytics?.ga || config?.userAnalytics?.meta) {
        useGTM(config?.userAnalytics?.ga);
        useMetaPixel(config?.userAnalytics?.meta);
      }
    }
  }, [config]);

  const getData = useCallback(async () => {
    const { ok, data, status } = await getPage(pageId);
    if (ok && data.page?.pageStatus !== PageStatus.OUTAGE) {
      setPage(data.page);
    } else if (status === 404) {
      setPageStatus(PageStatus.ERROR_NOT_FOUND);
    } else if ([500, 502, 503, 504].includes(status) || data.page?.pageStatus === PageStatus.OUTAGE) {
      setPageStatus(PageStatus.OUTAGE);
    } else {
      setPageStatus(PageStatus.ERRORED);
      toast.error('Something went wrong. Please try again.');
    }
  }, [pageId]);

  React.useEffect(() => {
    if (page) {
      // if no note and page is viewed or unviewed, there is some error along the way
      if (!page.currentNote
        && [PageStatus.UNVIEWED, PageStatus.VIEWED].includes(page.pageStatus)) {
        setPageStatus(PageStatus.ERRORED);
      } else {
        setPageStatus(page.pageStatus);
      }
    }
  }, [page]);

  React.useEffect(() => {
    if (
      page
      && (!currentUser || currentUser.id !== page.currentNote?.creatorId)
      && searchParams.get('preview') === null
      && !page.noTracking
    ) {
      createEvent({
        eventType: EventType.PAGE_VIEWED,
        resourceType: 'pages',
        resourceId: page.id,
        userId: currentUser?.id || null,
        metadata: {
          noteId: page.currentNote?.id || null,
          pageStatus: page.pageStatus,
          temporaryStatus: page.temporaryStatus,
          userAgent: getUA,
        },
      });
    }
  }, [page]);

  React.useEffect(() => {
    if (page && localPageOwner && currentUser) {
      updatePage(page.id, { newOwner: true }).then(({ ok }) => {
        if (ok) {
          setLocalPageOwner(null);
          getData();
        }
      });
    }
  }, [page, localPageOwner, currentUser]);

  React.useEffect(() => {
    if (isLoading) {
      setIsLoading(false);
      getData();
    }
  }, []);

  React.useEffect(() => {
    if (accessUrls) {
      // save the last 10 unique page ids
      const ids = accessUrls.split(',');
      const newIds = [pageId, ...ids.filter((id) => id !== pageId)].slice(0, 10);
      setAccessUrls(newIds.join(','));
    } else {
      setAccessUrls(pageId);
    }
  }, [pageId]);

  let child;

  switch (pageStatus) {
    case PageStatus.UNASSIGNED:
      child = <NoteCreateState page={page} setPage={setPage} setLocalPageOwner={setLocalPageOwner} />;
      break;
    case PageStatus.CLAIMED:
      child = <NoteClaimedState page={page} setPage={setPage} />;
      break;
    case PageStatus.VIEWED:
    case PageStatus.UNVIEWED:
      child = <NoteViewState page={page} setPage={setPage} />;
      break;
    case PageStatus.ERRORED:
      child = <NoteErrorState page={page} setPage={setPage} />;
      break;
    case PageStatus.UNDER_REVIEW:
      child = <NoteUnderReviewState page={page} setPage={setPage} />;
      break;
    case PageStatus.DELETED:
      child = <NoteDeletedState page={page} setPage={setPage} />;
      break;
    case PageStatus.LOCKED:
      child = <NoteProcessingState page={page} setPage={setPage} getData={getData} />;
      break;
    case PageStatus.ERROR_NOT_FOUND:
      child = <NoteErrorNotFoundState />;
      break;
    case PageStatus.OUTAGE:
      child = <NoteErrorOutage />;
      break;
    default:
      child = <NoteStateSkeleton page={page} setPage={setPage} />;
  }

  if (page && page.temporaryStatus) {
    if (page.temporaryStatus === TemporaryPageStatus.UNDER_REVIEW) {
      child = <NoteUnderReviewState page={page} setPage={setPage} />;
    } else if (page.temporaryStatus === TemporaryPageStatus.DELETED) {
      child = <NoteDeletedState page={page} setPage={setPage} />;
    }
  }

  return (
    <div className="page">
      <Helmet>
        <body
          className={(!page || config.isWhitelabeled) ? 'whitelabeled' : 'not-whitelabeled'}
        />
      </Helmet>
      {child}
      {(localPageOwner && page?.pageStatus !== PageStatus.UNASSIGNED) && (
        <div>
          {!currentUser && !config.isLoginPromptDisabled && (
            <div className="auth-wrapper center-text">
              <div className="auth-form">
                <p>
                  Add this
                  {isPhotoUpload(config) ? ' photo ' : ' video '}
                  to your account to see when it is viewed.
                </p>
                <div className="actions">
                  <Link className="btn mb-0" to="/login">Login</Link>
                  <Link className="btn mb-0" to="/signup">Signup</Link>
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      <Footer
        page={page}
      />
    </div>
  );
}

export default AccessContent;
